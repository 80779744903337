<template>
  <div style="padding-bottom: 80px;">
    <you-lost-access-sidebar
      :show-open-you-lost-access-sidebar.sync="showOpenYouLostAccessSidebar"
      :url="idsite.domain"
    />
    <activate-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenArchiveSite"
    />
    <b-row class="align-items-center mb-4">
      <b-col md="6">
      </b-col>
      <b-col md="6">
        <div class="d-flex float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ name: 'projects' }"
            class="pt-auto mr-1"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            <span class="align-middle ">Active sites</span>
          </b-button>
          <!-- <vue-autosuggest
            v-model="searchSite"
            :suggestions="suggestionsSites"
            :input-props="inputProps"
            :section-configs="sectionConfigs"
            :render-suggestion="renderSuggestion"
            @input="fetchResults"
            style="width: 325px; height: 44px; border: 1px solid transparent; border-radius: 22px;"
            class="autosuggest-sites"
          >
          </vue-autosuggest> -->
        </div>
      </b-col>
    </b-row>

    <div v-if="existSites">
      <div v-if="rows.length >= 1" :class="'top-scroll-horizontal-archived'+0" :style="'overflow-x: scroll;'"><div class="div1" :id="'tab-err-sc2'+0" style="height: 20px;"></div></div>
      <div v-for="(row, index) in rowsAtomic.length >= 1 ? rowsAtomic : rows" :key="index">
        <div v-if="row.children.length >= 1">
          <!-- <div v-if="row.children.length >= 1" :class="'top-scroll-horizontal-archived'+index" :style="index === 0 ? 'overflow-x: scroll;' : 'overflow-x: scroll; display: none !important;'"><div class="div1" :id="'tab-err-sc2'+index" style="height: 20px;"></div></div> -->
          <b-table-simple
            hover
            small
            caption-top
            responsive
            :id="'tab-err-sc1'+index"
            :class="`table-archived vgt-table`"
            striped
            :style="rows.length === (index + 1) ? 'overflow-x: hidden !important;' : 'overflow-x: hidden !important;'"
          >
            <b-thead head-variant="light" v-if="row.children.length >= 1">
              <b-tr>
                  <b-td colspan="9" style="border-top: none !important;">
                      <span class="mr-50" style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; display: flex; margin-top: 0px;" v-if="row.id && row.label">
                          <div :style="`width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;`" class="mr-50"></div>
                          <b-link v-if="row.label !== 'My sites'" :style="row.noExistSites ? 'color: #A7AAB9 !important; padding-top: 16px;' : 'color: #A7AAB9 !important; padding-top: 16px;'">Project</b-link>
                      </span>
                      <span class="" :style="`font-weight: 700; font-size: 16px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                          <div :style="`width: 12px; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                          <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 33%;">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                          <b-link v-if="row.label !== 'My sites'" style="color: #181E38 !important; max-width: 33%;">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                      </span>
                  </b-td>
              </b-tr>
              <b-tr>
                  <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
                      <span>{{ column.label }}</span>
                  </b-td>
              </b-tr>
            </b-thead>
            <b-tbody v-if="row.children.length >= 1">
              <b-tr v-for="(siteRow, indexSite) in row.children" :key="indexSite" @click.prevent="logIdArchive(siteRow)">
                <b-td v-if="siteRow.imgSite" class="tdTableSitesImg">
                    <b-img-lazy :src="siteRow.imgSite" onerror="this.onerror=null;this.src='https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b';" width="120px" height="70px" class="my-50" loading="lazy" style="padding-left: 0px !important;" :alt="'img not found'" />
                </b-td>
                <b-td v-if="siteRow.name" class="tdTableSitesNameArchive">
                    <div :style="siteRow.name === 'Mad House' ? 'top: -12px; position: relative;' : ''">
                        <span class="" style="font-size: 15px; color: #181E38;">{{ siteRow.name }}</span>
                        <div style="width: 200px; position: absolute;" v-if="siteRow.name === 'Mad House'">
                            <b-progress
                                value="10"
                                max="12"
                                class="progress-bar-info mt-25"
                                variant="info"
                                style="height: 5px;"
                            />
                            <span class="" style="color: #A7AAB9; font-size: 14px;">Crawling 10 of 12 pages</span>
                        </div>
                    </div>
                </b-td>
                <b-td v-if="siteRow.domainFix" class="tdTableSitesDomainArchive">
                    <span class="" :style="siteRow.name === 'Mad House' ? 'top: -12px; position: relative; font-size: 15px; color: #A7AAB9;' : 'font-size: 15px; color: #A7AAB9;'">{{ siteRow['domainFix'] }}</span>
                </b-td>
                <b-td v-if="siteRow.domainFix" class="tdTableSitesOwned">
                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      no-caret
                      variant="flat-dark"
                      class="btn-icon dropdown-sites rounded-circle"
                    >
                      <template #button-content class="pt-0">
                        <feather-icon icon="MoreVerticalIcon" class="mt-0 pt-0" size="20" />
                      </template>
                      <b-dropdown-item
                        link-class="d-flex align-items-center  item-dropdown-action-sites"
                        @click="logIdArchive(siteRow)"
                      >
                        <feather-icon
                          icon="ArchiveIcon"
                          class="mr-50"
                          style="position: relative !important; left: -7px !important;"
                        />
                        Activate Site
                      </b-dropdown-item>
                    </b-dropdown>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
      <div v-if="rows.length >= 1" :class="'top-scroll-horizontal-archived'+0+0" :style="'overflow-x: scroll;'"><div class="div1" :id="'tab-err-sc2'+0+0" style="height: 20px;"></div></div>
    </div>
    <div v-else>
      <b-table-simple
        hover
        small
        caption-top
        responsive
        striped
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-td v-for="(column, indexCol) in columns" :key="indexCol" :class="column.thClass">
              <span>{{ column.label }}</span>
            </b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td colspan="9">
              <span>No sites</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BImgLazy, BButton, BAvatarGroup, BLink,
  BRow, BCol, BProgress, BTableSimple, BTfoot, BTr, BTd, BTbody, BThead, BTh, BCardText, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import TitlePage from '@/@core/layouts/components/app-navbar/components/TitlePage.vue'
// import TitlePage from './components/TitlePage.vue'
import { avatarText } from '@core/utils/filter'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import YouLostAccessSidebar from './sidebar/YouLostAccessSidebar.vue'
import SidebarOwnership from './sidebar/SidebarOwnership.vue'
import ChangeProject from './sidebar/ChangeProject.vue'
import ActivateSite from './sidebar/ActivateSite.vue'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import { db, auth, storage, firebase } from '@/firebase'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BAvatarGroup,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BImgLazy,
    BButton,
    BLink,
    BRow,
    BCol,
    BProgress,
    BTableSimple,
    BTfoot,
    BTr,
    BTd,
    BTbody,
    BThead,
    BTh,
    BCardText,
    BSpinner,
    vSelect,
    YouLostAccessSidebar,
    SidebarOwnership,
    ChangeProject,
    ActivateSite,
    VueAutosuggest,
    // TitlePage,
  },
  directives: {
    Ripple,
  },
  props: [],
  data() {
    return {
      titlePage: '',
      selectedPlan: 'Basic',
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      optionsPlan: [
        'Free',
        'Basic',
        'Pro',
        'Custom license',
      ],
      iduser: '',
      userData: '',
      loaderSearch: true,

      // table
      columns: [
        {
          label: '',
          field: 'imgSite',
          sortable: false,
          thClass: 'thTableSites',
          tdClass: 'tdTableSitesImg',
        },
        {
          label: 'Site Name',
          field: 'name',
          thClass: 'thTableSitesNameArchive',
          tdClass: 'tdTableSitesNameArchive',
        },
        {
          label: 'URL',
          field: 'domainFix',
          thClass: 'thTableSitesDomainArchive',
          tdClass: 'tdTableSitesDomainArchive',
        },
        // {
        //   label: 'Current Errors',
        //   field: 'currentErrors',
        //   thClass: 'thTableSites',
        //   tdClass: 'tdTableSites',
        // },
        // {
        //   label: 'Fixed Issues',
        //   field: 'fixedIssues',
        //   thClass: 'thTableSites',
        //   tdClass: 'tdTableSites',
        // },
        // {
        //   label: 'Key',
        //   field: 'key',
        //   sortable: false,
        //   thClass: 'thTableSites',
        //   tdClass: 'tdTableSites',
        // },
        // {
        //     label: 'Alerts',
        //     field: 'alerts',
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSites'
        // },
        // {
        //   label: 'Membership',
        //   field: 'membership',
        //   sortable: false,
        //   thClass: 'thTableSitesViewListMembership',
        //   tdClass: 'tdTableSitesViewListMembership',
        // },
        // {
        //     label: 'Owned by',
        //     field: 'ownedBy',
        //     sortable: false,
        //     thClass: 'thTableSites',
        //     tdClass: 'tdTableSites'
        // },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'thTableSites',
          tdClass: 'tdTableSites',
        },
      ],
      rows: [],
      rowsAtomic: [],
      dir: false,

      // suggest
      datasuggest: {
        data: [{
          sites: [],
        }],
      },
      searchSite: '',
      suggestionsSites: [],
      selected: null,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search",
      },
      sectionConfigs: {
        sites: {
          limit: 60,
          // label: 'Sites',
          onSelected: selected => {
            // console.log(selected.item)
            this.selected = selected.item
          },
        },
        default: {
          limit: 60,
          onSelected: selected => {
            // console.log(selected)
            this.selected = selected.item
          }
        },
      },
      limit: 60,
      arrayTeamSort: [],
      arrayInvitations: [],
      pageLength: 5,
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      shared: false,
      isBusy: false,
      counterTotalSites: 0,
      extraWP: 0,
      existSites: false,

      // sidebar ownership
      idsite: '',
      showOpen: false,
      showOpenChangeProject: false,
      showOpenArchiveSite: false,
      showOpenYouLostAccessSidebar: false,
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    searchSite(val) {
      setTimeout(() => {
        const collection = document.getElementsByClassName('vgt-text-disabled')
        if(collection[0] && val) {
          collection[0].innerHTML = 'No results found'
        }
      }, 1000)
    },
    rows(val) {
      // console.log(val)
      if(!this.existSites) {
        val.forEach(item => {
          if(item.children.length >= 1) {
            this.existSites = true
          }
        })
      }
    },
    selected(val) {
      // console.log(val)
      this.getSite(val)
    },
  },
  mounted() {
    this.existSites = false
    this.events.forEach(event => {
      window.addEventListener(event, this.getWithScroll)
    })
  },
  created() {
    const user = auth.currentUser
    if(user) {
      // console.log(user.uid)
      db.collection('Users').doc(user.uid)
      .onSnapshot(userDoc => {
        this.userData = userDoc.data()
        this.rows = []
        this.iduser = userDoc.data()
        // this.charge(this.iduser)

        db.collection('Users').doc(user.uid).collection('projects').get()
        .then(userDoc => {
          userDoc.forEach(eleUserProject => {
            var arraySitesChildren = []

            if(eleUserProject.data().name === 'My sites') {
              // this.rowsAllProjects.unshift({
              //   mode: 'span',
              //   html: false,
              //   label: eleUserProject.data().name,
              //   color: eleUserProject.data().color,
              //   idsite: eleUserProject.data().id,
              //   id: eleUserProject.data().id,
              //   title: eleUserProject.data().name,
              //   count: eleUserProject.data().numberSites,
              //   permission: 'owner',
              //   totalInv: eleUserProject.data().numberTeamMembers,
              //   members: eleUserProject.data().teamMembers,
              //   own: 'me',
              //   ownedBy: eleUserProject.data().owner,
              //   counterErr: 0,
              //   loadErrors: false,
              //   counterSites: eleUserProject.data().numberSites,
              //   invitation: 'own',
              // })

              db.collection('Projects').doc(eleUserProject.data().id).collection('sites').where('status', '==', 'archive').get()
              .then(queryArchiveProject => {
                queryArchiveProject.forEach(site => {
                  arraySitesChildren.push({
                    imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                    name: site.data().name ? site.data().name : site.data().WebsiteURL,
                    domainFix: site.data().WebsiteURL,
                    domain: site.data().homepage,
                    currentErrors: '0',
                    fixedIssues: '79',
                    key: site.id,
                    alerts: '3',
                    membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                    ownedBy: '',
                    label: eleUserProject.data().name,
                    color: eleUserProject.data().color,
                    id: site.id,
                    projectid: eleUserProject.data().id,
                    owner: 'me',
                    permission: 'owner'
                  })
                  this.extraWP += 1
                })

                if(arraySitesChildren.length) {
                  this.rows.unshift({
                    mode: 'span',
                    html: false,
                    label: eleUserProject.data().name,
                    color: eleUserProject.data().color,
                    // idsite: site.id,
                    id: eleUserProject.data().id,
                    permission: 'owner',
                    title: eleUserProject.data().name,
                    count: this.extraWP,
                    own: 'me',
                    children: arraySitesChildren,
                  })
                }
              })
            } else if(eleUserProject.data().owner.uid === user.uid) {
              // this.rowsAllProjects.push({
              //   mode: 'span',
              //   html: false,
              //   label: eleUserProject.data().name,
              //   color: eleUserProject.data().color,
              //   idsite: eleUserProject.data().id,
              //   id: eleUserProject.data().id,
              //   title: eleUserProject.data().name,
              //   count: eleUserProject.data().numberSites,
              //   permission: 'owner',
              //   totalInv: eleUserProject.data().numberTeamMembers,
              //   members: eleUserProject.data().teamMembers,
              //   own: 'me',
              //   ownedBy: eleUserProject.data().owner,
              //   counterErr: 0,
              //   loadErrors: false,
              //   counterSites: eleUserProject.data().numberSites,
              //   invitation: 'own',
              // })
              db.collection('Projects').doc(eleUserProject.data().id).collection('sites').where('status', '==', 'archive').get()
              .then(queryArchiveProject => {
                queryArchiveProject.forEach(site => {
                  arraySitesChildren.push({
                    imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                    name: site.data().name ? site.data().name : site.data().WebsiteURL,
                    domainFix: site.data().WebsiteURL,
                    domain: site.data().homepage,
                    currentErrors: '0',
                    fixedIssues: '79',
                    key: site.id,
                    alerts: '3',
                    membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                    ownedBy: '',
                    label: eleUserProject.data().name,
                    color: eleUserProject.data().color,
                    id: site.id,
                    projectid: eleUserProject.data().id,
                    owner: 'me',
                    permission: 'owner'
                  })
                  this.extraWP += 1
                })

                if(arraySitesChildren.length) {
                  this.rows.push({
                    mode: 'span',
                    html: false,
                    label: eleUserProject.data().name,
                    color: eleUserProject.data().color,
                    // idsite: site.id,
                    id: eleUserProject.data().id,
                    permission: 'owner',
                    title: eleUserProject.data().name,
                    count: this.extraWP,
                    own: 'me',
                    children: arraySitesChildren,
                  })
                }
              })
            } else {
              var filterUserMember = eleUserProject.data().teamMembers.filter(ele => ele.id === user.uid)

              if(filterUserMember.length >= 1) {
                var memberPerm = filterUserMember[0].role
                // this.rowsAllProjects.push({
                //   mode: 'span',
                //   html: false,
                //   label: eleUserProject.data().name,
                //   color: eleUserProject.data().color,
                //   idsite: eleUserProject.data().id,
                //   id: eleUserProject.data().id,
                //   title: eleUserProject.data().name,
                //   count: eleUserProject.data().numberSites,
                //   permission: memberPerm,
                //   totalInv: eleUserProject.data().numberTeamMembers,
                //   members: eleUserProject.data().teamMembers,
                //   own: 'other',
                //   ownedBy: eleUserProject.data().owner,
                //   counterErr: 0,
                //   loadErrors: false,
                //   counterSites: eleUserProject.data().numberSites,
                //   invitation: filterUserMember[0]
                // })
                db.collection('Projects').doc(eleUserProject.data().id).collection('sites').where('status', '==', 'archive').get()
                .then(queryArchiveProject => {
                  queryArchiveProject.forEach(site => {
                    arraySitesChildren.push({
                      imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                      name: site.data().name ? site.data().name : site.data().WebsiteURL,
                      domainFix: site.data().WebsiteURL,
                      domain: site.data().homepage,
                      currentErrors: '0',
                      fixedIssues: '79',
                      key: site.id,
                      alerts: '3',
                      membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                      ownedBy: '',
                      label: eleUserProject.data().name,
                      color: eleUserProject.data().color,
                      id: site.id,
                      projectid: eleUserProject.data().id,
                      owner: 'other',
                      permission: memberPerm
                    })
                    this.extraWP += 1
                  })

                  if(arraySitesChildren.length) {
                    this.rows.push({
                      mode: 'span',
                      html: false,
                      label: eleUserProject.data().name,
                      color: eleUserProject.data().color,
                      // idsite: site.id,
                      id: eleUserProject.data().id,
                      permission: memberPerm,
                      title: eleUserProject.data().name,
                      count: this.extraWP,
                      own: 'other',
                      children: arraySitesChildren,
                    })
                  }
                })
              }
            }
          })

          // loader all list
          setTimeout(() => {
            // console.log(this.rows.length);
            for(let index = 0; index < this.rows.length; index++) {
              if(this.rows[index].children.length) {

                this.loaderProjects = false

                // horizontal scroll
                this.$nextTick(() => {
                  $(".top-scroll-horizontal-archived"+0).scroll(() => {
                    $(".vgt-table").scrollLeft($(".top-scroll-horizontal-archived"+0).scrollLeft());
                  });
                });

                this.$nextTick(() => {
                  $(".top-scroll-horizontal-archived"+0+0).scroll(() => {
                    $(".vgt-table").scrollLeft($(".top-scroll-horizontal-archived"+0+0).scrollLeft());
                  });
                });

                setTimeout(() => {
                  var miElemento = document.querySelector('.vgt-table');
                  // console.log(miElemento);
                  if(miElemento) {
                    miElemento.addEventListener('scroll', () => {
                      document.querySelector('.top-scroll-horizontal-archived'+0).scrollLeft = document.querySelector('.vgt-table').scrollLeft;

                      document.querySelector('.top-scroll-horizontal-archived'+0+0).scrollLeft = document.querySelector('.vgt-table').scrollLeft;
                    });
                  }
                }, 800)
              }
            }
          }, 2000)
        })
      }, error => console.log(error))
    }
  },
  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.getWithScroll)
    })
  },
  methods: {
    fetchResults(text) {
      const { searchSite } = this
      if (searchSite) {
        this.suggestionsSites = []
      }

      const filteredDevelopers = this.datasuggest.data[0].sites.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.domain.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      filteredDevelopers.length && this.suggestionsSites.push({ data: filteredDevelopers })
      // name: 'sites',
    },
    getSite(val){
      // console.log(val)
      if(val.id) {
        if(val.id && val.own === 'other' && val.invitation.statusInv === 'sended') {
          this.logIdAcceptInvitation(val)
        } else if(val.status === 'archive') {
          this.logIdArchive(val)
        } else {
          this.$router.push({ name: 'view-errors', params: {id: val.id, imgSite: val.img, name: val.name, url: val.domain, project: val.projectid, own: val.own} })
        }
      }
    },
    renderSuggestion(suggestion) {
      const site = suggestion.item
      if(this.loaderSearch && this.datasuggest.data[0].sites.length === 0) {
        return (
          <div class="py-50">
            <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
              <b-spinner class="align-middle"></b-spinner>
            </span>
          </div>
        )
      } else {
        if(suggestion.item.id) {
          return (
            <div class="py-50">
              {site.project !== 'My sites' ?
              <span class="mr-50" style="line-height: 0.8rem !important; font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 12px; display: flex; margin-top: 0px;">
                <div style="width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;" class="mr-50"></div>
                <span style="color: #A7AAB9 !important; line-height: 0.8rem !important;">Project</span>
              </span>
              : ''}
              <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                <div style={"width: 12px; height: 12px; border-radius: 4px; background: "+site.color+"; margin-top: 6px;"} class="mr-50"></div>
                <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{site.project.length > 35 ? site.project.substring(0, 35) + '...' : site.project}</span>
              </span>
              <div class="d-flex align-items-center my-50">
                <b-avatar square src={site.img} class="mr-50"></b-avatar>
                <div class="detail" style="line-height: 0.8rem !important;">
                  <b-card-text class="mb-0" style="font-size: 16px; line-height: 1.2rem !important;">{site.name.length > 55 ? site.name.substring(0, 55) + '...' : site.name}</b-card-text>
                  <b-card-text class="text-muted mb-0" style="font-size: 14px; line-height: 1rem !important;">{site.domainFixed.length > 35 ? site.domainFixed.substring(0, 35) + '...' : site.domainFixed}</b-card-text>
                </div>
              </div>
              { site.status === 'archive' ? 
                <b-button
                  variant="secondary"
                  style="background: #F0F1F7 0% 0% no-repeat padding-box !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 2px 7px; border-radius: initial;"
                  class="p-auto mr-50"
                >
                  <feather-icon
                    icon="ArchiveIcon"
                    class="mr-50"
                  />
                  <span class="align-middle ">Archived</span>
                </b-button>
              : '' }
            </div>
          )
        } else {
          if(this.loaderSearch) {
            return (
              <div class="py-50">
                <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                  <b-spinner class="align-middle"></b-spinner>
                </span>
              </div>
            )
          } else {
            return (
            <div class="py-50">
              <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
              <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{'No results'}</span>
              </span>
            </div>
            )
          }
        }
      }
    },
    logIdSite(val) {
      // console.log(val)
      this.idsite = val
      this.showOpen = true
    },
    logIdChangeProject(val) {
      // console.log('first')
      this.idsite = val
      this.showOpenChangeProject = true
    },
    logIdArchive(val) {
      // console.log(val)
      this.idsite = val
      this.showOpenArchiveSite = true
    },
    charge(val) {
      if(this.rows.length === 0) {
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Key copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy key!',
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    },
    getSharedStatus() {
      // setTimeout(() => {
      //   this.shared = localStorage.getItem('filtShared')
      //   this.isBusy = true
      //   if (this.shared === 'true') {
      //     this.rowsAtomic = this.rows.filter(user => user.own === 'other')
      //     if (this.rowsAtomic.length >= 1) {
      //       this.rowsAtomic.forEach(item => {
      //         this.rowsAtomic.push({
      //           mode: 'span',
      //           html: false,
      //           label: '',
      //           color: '',
      //           id: item.idsite,
      //           title: item.label,
      //           count: 12,
      //           own: 'other',
      //           children: [
      //             {},
      //           ],
      //         })
      //       })
      //     }
      //     this.isBusy = false
      //   } else {
      //     this.rowsAtomic = []
      //     this.isBusy = false
      //   }
      // }, 500)
    },
    getWithScroll() {
      for(let index = 0; index < this.rows.length; index++) {
        // console.log(index);
        // const element = array[rowsAtomic];
        var primerElemento = document.getElementById('tab-err-sc1'+0);
        var segundoElemento = document.getElementById('tab-err-sc2'+0);
        var tercerElemento = document.getElementById('tab-err-sc2'+0+0);

        if(primerElemento) {
          const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
          tercerElemento.style.width = ancho + 'px';
          segundoElemento.style.width = ancho + 'px';

          // const ancho2 = segundoElemento.offsetWidth; // Obtiene el ancho del primer elemento
          
        }
      }
    },
  },
}
</script>

<style>
.autosuggest-sites .autosuggest__results-container {
  max-width: 350px !important;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
}
.tdTableSitesImg {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  width: 120px !important;
  max-width: 120px !important;
  padding-left: 0px !important;
  /* margin: 20px !important; */
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  vertical-align: middle !important;
  /* margin: 20px !important; */
}
.thTableSitesNameArchive {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  padding-right: 0.75em !important;
  min-width: 160px !important;
  max-width: 360px !important;
  width: 360px !important;
}
.tdTableSitesNameArchive {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  vertical-align: middle !important;
  min-width: 160px !important;
  max-width: 360px !important;
  width: 360px !important;
  padding-left: 12px !important;
  padding-right: 8px !important;
}
.thTableSitesViewListMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
  text-align: center !important;
  /* padding-right: 0.75em !important; */
  padding-right: 30px !important;
}
.tdTableSitesViewListMembership {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  vertical-align: middle !important;
  padding-right: 30px !important;
  /* margin: 20px !important; */
}
.thTableSitesDomainArchive {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  max-width: 425px;
  font-weight: 300;
}
.tdTableSitesDomainArchive {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  cursor: pointer;
  /* max-width: 425px; */
  min-width: 280px !important;
  word-wrap: break-word;
  vertical-align: middle !important;
  /* margin: 20px !important; */
}
.vgt-table.bordered th {
  background: #fff !important;
  border: 1px solid transparent !important;
  margin-top: 20px !important;
  /* font-size: 16px !important; */
  /* color: #181E38 !important; */
}
.item-dropdown-action-sites:hover {
  background: none !important;
  background-color: none !important;
  color: #000 !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  padding: 0px !important;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
  padding: 0px !important;
}
.style-chooser .dropdown-toggle {
    background: transparent !important;
    border: 0px !important;
    padding: 0px !important;
    color: #656B85 !important;
}
.style-chooser .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' stroke='' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.style-chooser .dropdown-item {
    padding: 0px 5px;
}
.style-chooser .dropdown-menu {
    border-radius: 10px;
    border: 1px solid #bbbbb9 !important;
    width: 180px !important;
}
.autosuggest-sites > div > input {
  width: 325px;
  height: 44px;
  border: 1px solid #A7AAB9;
  border-radius: 22px;
}
.autosuggest-sites.autosuggest__results-container {
  background: #fff !important;
}
/* .vgt-table.bordered th {
  border: 1px solid #dcdfe6;
} */
/* .vgt-table thead th {
  border-bottom: 1px solid #dcdfe6;
  background: linear-gradient(#f4f5f8,#f1f3f6);
} */
</style>
